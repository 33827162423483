import React, { useState, useEffect } from 'react'
import '../components/global.css'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Header from '../components/Header.tsx'
import Opening from '../components/Openinig.tsx'
import Gallery from '../components/Gallery.tsx'
import Location from '../components/Location.tsx'
import Area from '../components/Area.tsx'
import Standard from '../components/Standard.tsx'
import { StyledSeparator } from '../components/Openinig'
import Footer from '../components/Footer.tsx'
import SEO from '../components/seo'
import config from 'react-reveal/globals'
import Fade from 'react-reveal/Fade'
import pdf from '../data/svg/pdf.svg'
import { seaBlue } from '../components/variables'
import Community from '../components/Community'

config({ ssrFadeout: true })

const IndexPage = ({ data }) => {
  const [lang, setLang] = useState('PL')

  const [zeroOffset, setOffset] = useState(true)
  const offsetHandler = () => {
    if (window.pageYOffset !== 0) {
      setOffset(false)
    }
    if (window.pageYOffset === 0 && zeroOffset === false) {
      setOffset(true)
    }
  }

  useEffect(() => {
    let debounce_timer
    window.onscroll = () => {
      if (debounce_timer) {
        window.clearTimeout(debounce_timer)
      }
      debounce_timer = window.setTimeout(function() {
        offsetHandler()
      }, 50)
    }
  }, [])

  const lngChangeHandler = event => {
    event.preventDefault()
    lang === 'PL' ? setLang('EN') : setLang('PL')
  }

  const downloadPlan = e => {
    e.target.download = 'pp.pptx'
  }

  const node = data.allDataJson.edges[0].node
  const sections = data.allDataJson.edges[0].node.sections

  return (
    <div>
      {typeof window === 'object' ? (
        <div>
          <Header lang={lang} data={sections} lngChangeHandler={lngChangeHandler} propertyName={node.propertyName} />
          {/* <StyledLinkFixed onClick={downloadPlan} href={lang === 'PL' ? 'pp.pptx' : 'pp.pptx'}> */}
          {/* <StyledPdf src={pdf} alt="pdf" /> */}
          {/* {node.sections.area[`${lang}`].plansLabel} */}
          {/* </StyledLinkFixed> */}
          <main style={{ overflow: 'hidden' }}>
            <Opening
              image={node.openingSection.propertyOpeningPhoto}
              imageMobile={node.openingSection.propertyOpeningPhotoMobile}
              openingSubtitle={node.openingSection[lang].openingSubtitle}
              propertyName={node.propertyName}
              locationId={sections.location[lang].sectionTitle.toUpperCase()}
              seeMoreButton={node.openingSection[lang].seeMoreButton}
              portfolioButton={node.openingSection[lang].portfolioButton}
              descriptionInfo={node.descriptionSection[lang]}
              descriptionInfoStatic={node.descriptionSection}
              zeroOffset={zeroOffset}
            />
            <StyledSeparator />
            <StyledContainer id={sections.gallery[lang].sectionTitle.toUpperCase()}>
              <Gallery images={node.galleryImages} data={sections.gallery[lang]} />
            </StyledContainer>

            <Location
              propertyName={node.propertyName}
              data={sections.location[lang]}
              mapData={sections.location.mapData}
              transportTime={sections.location.transportTime}
              transportIconLoc={sections.location.transportLoc}
              zeroOffset={zeroOffset}
            />
            <Fade>
              <StyledSeparator />
              {/* <Area
                planParticulars={sections.area.planParticulars}
                planImg={sections.area.planImg}
                data={sections.area[lang]}
                lang={lang}
              /> */}
              <Standard data={sections.standard[lang]} />
              <Footer lang={lang} data={sections.contact[lang]} contactData={sections.contact} footerLogo={node.footerLogo}></Footer>
            </Fade>
            <SEO />
          </main>
        </div>
      ) : null}
    </div>
  )
}

export default IndexPage

export const StyledContainer = styled.div`
  width: 1190px;
  margin: 0 auto;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;

  @media only screen and (max-width: 766.99px) {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
  }

  @media only screen and (max-width: 1023.99px) and (min-width: 768px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media only screen and (max-width: 1365.99px) and (min-width: 1025px) {
    width: 992px;
    padding-left: 10px;
    padding-right: 10px;
  }
`

const StyledLinkFixed = styled.a`
  position: fixed;
  top: 90px;
  right: 20px;
  background: ${seaBlue};
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  z-index: 9;
  @media screen and (max-width: 768px) {
    font-size: 6px;
    width: 70px;
    height: 70px;
    top: 85px;
    right: 8px;
  }
`
const StyledPdf = styled.img`
  width: 43px;
  height: 41px;
  margin-right: 5px;
  @media screen and (max-width: 768px) {
    width: 28px;
    height: 27px;
  }
`

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          footerLogo {
            childImageSharp {
              fluid(maxWidth: 317, quality: 100) {
                aspectRatio
                srcSet
                src
                sizes
              }
            }
          }
          propertyName
          galleryImages {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 90) {
                aspectRatio
                srcSet
                src
                sizes
              }
            }
          }
          openingSection {
            PL {
              openingSubtitle
              seeMoreButton
            }
            EN {
              openingSubtitle
              seeMoreButton
            }
          }
          descriptionSection {
            totalLeaseArea
            floorLeaseArea
            PL {
              portfolioButton
              propertyDecription
              locationButtonLabel
              boxLabel
              firstBoxSublabel
              secondBoxSublabel
              metricUnit
            }
            EN {
              portfolioButton
              propertyDecription
              locationButtonLabel
              boxLabel
              firstBoxSublabel
              secondBoxSublabel
              metricUnit
            }
          }
          sections {
            gallery {
              PL {
                sectionTitle
                showAllLabel
                galleryTitle
              }
              EN {
                sectionTitle
                showAllLabel
                galleryTitle
              }
            }
            location {
              mapData {
                lat
                lng
                mapZoom
              }
              transportTime {
                timeFromCenter
                tramTransportTime
                busTransportTime
                subwayTransportTime
                highwayTransportTime
                trainstationTransportTime
                airportTransportTime
                galleryTransportTime
                trainStopTransportTime
                secondGalleryTransportTime
              }
              transportLoc {
                tram {
                  lat
                  lng
                }
                bus {
                  lat
                  lng
                }
                subway {
                  lat
                  lng
                }
                highway {
                  lat
                  lng
                }
                trainstation {
                  lat
                  lng
                  name
                }
                airport {
                  lat
                  lng
                }
                gallery {
                  lat
                  lng
                  name
                }
              }
              PL {
                centerDistance
                sectionTitle
                addressLabel
                onFootLabel
                byCarLabel
                tramLabel
                busLabel
                subwayLabel
                galleryLabel
                highwayLabel
                trainstationLabel
                airportLabel
                trainStopLabel
                secondGalleryLabel
                adress
                adressUrl
              }
              EN {
                centerDistance
                sectionTitle
                addressLabel
                onFootLabel
                byCarLabel
                tramLabel
                busLabel
                subwayLabel
                galleryLabel
                highwayLabel
                trainstationLabel
                trainStopLabel
                airportLabel
                secondGalleryLabel
                adress
                adressUrl
              }
            }

            standard {
              EN {
                sectionTitle
                sectionSubtitle
                standardSlides {
                  title
                }
              }
              PL {
                sectionTitle
                sectionSubtitle
                standardSlides {
                  title
                }
              }
            }
            contact {
              companyName
              street
              postcode
              websiteAdress
              credits
              contactPersons {
                name
                position
                phoneNumber
                email
              }
              PL {
                sectionTitle
                AdresSectionSubtitle
                companyDescription
                city
                locationsTitle
                disclaimer
              }
              EN {
                sectionTitle
                AdresSectionSubtitle
                companyDescription
                city
                locationsTitle
                disclaimer
              }
            }
          }
        }
      }
    }
  }
`
