import React from 'react'
import styled, { keyframes } from 'styled-components'
import { StyledContainer } from '../pages/index'
import { StyledSeparator } from './Openinig'
import Download from './svg/Download'
import Img from 'gatsby-image'
import { success, fontColor } from './variables'
import Chevron from './svg/Chevron'

interface AreaProps {
  lang: string
  data: {
    areaDesc: string
    areaHeader: string
    plansLabel: string
    sectionTitle: string
    sectionTitleInline: string
  }
  planImg: {
    childImageSharp: {
      fluid: object
    }
  }
}

const Area: React.FC<AreaProps> = ({ data, planImg, lang }) => {
  const downloadPlan = e => {
    e.target.download = 'pp.pptx'
  }

  console.log(planImg)

  return (
    <StyledSection id={`${data.sectionTitle.toUpperCase()}`}>
      <StyledContainer>
        <StyledFlexContainer>
          <StyledHeaderWrapper>
            <Styledh2>{data.sectionTitleInline}</Styledh2>
            <Styledh3>{data.areaHeader}</Styledh3>
          </StyledHeaderWrapper>
        </StyledFlexContainer>
        <StyledPlanImg fluid={planImg.childImageSharp.fluid} />
        {/* <StyledLink
          onClick={downloadPlan}
          href={
            lang === "PL"
              ? "property_particulars.pdf"
              : "property_particulars_ENG.pdf"
          }
        >
          {data.plansLabel}
          <div style={{ marginLeft: "20px" }}>
            <Chevron
              arrowSize="20px"
              ovalSize="40px"
              color="#fff"
              backgroundColor={success}
            />
          </div>
        </StyledLink> */}
      </StyledContainer>
    </StyledSection>
  )
}

export default React.memo(Area)

const StyledSection = styled.section`
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding-top: 40px;
  }
`
export const Styledh2 = styled.h2`
  display: flex;
  align-items: center;
  height: 40px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 5.81px;
  color: ${success};
  text-transform: uppercase;
  margin: 15px;
`
const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-around;
`
const StyledHeaderWrapper = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const chevronAnimationDown = keyframes`
0% { transform : translateY(0); }
25% { transform : translateY(5px); }
75% { transform : translateY(-5px); }
100% { transform : translateY(0); }
`

const StyledLink = styled.a`
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 0.29px;
  color: ${fontColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 90px;
  text-decoration: none;
  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
    font-size: 18px;
  }
  &:hover {
    div {
      @media screen and (min-width: 992px) {
        animation: ${chevronAnimationDown} 0.6s ease-in-out infinite;
        cursor: pointer;
      }
    }
  }
`

export const Styledh3 = styled.h3`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.65px;
  color: ${fontColor};
  margin-top: 20px;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 35px;
  }
  @media screen and (max-width: 576px) {
    font-size: 28px;
  }
`

const StyledPlanImg = styled(Img)`
  width: 100%;
  margin-bottom: 40px;
`
