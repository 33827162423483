import React from 'react'

const Windows = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.84 34.27">
      <g data-name="Warstwa 2">
        <g data-name="Warstwa 1">
          <path d="M37.68,0H1.15A1.16,1.16,0,0,0,0,1.15v27.4A1.15,1.15,0,0,0,1.15,29.7h25.6v3.42a1.13,1.13,0,0,0,.27.74,1.16,1.16,0,0,0,.78.41h.1a1.21,1.21,0,0,0,.77-.29l4.49-4.28h4.52a1.15,1.15,0,0,0,1.16-1.15V1.15A1.16,1.16,0,0,0,37.68,0ZM1,28.55V1.15A.15.15,0,0,1,1.15,1H18.92V28.7H1.15A.15.15,0,0,1,1,28.55ZM27.15,10.36a1.18,1.18,0,0,0-.4.88V26.09H22.68a.15.15,0,0,1-.15-.15V3.76a.15.15,0,0,1,.15-.15H35.57ZM28,33.23a.12.12,0,0,1-.11,0,.17.17,0,0,1-.11-.06.14.14,0,0,1,0-.09V20.88l1.88.94a.48.48,0,0,0,.67-.22.49.49,0,0,0-.22-.67l-2.33-1.17V12.9h0V11.24a.14.14,0,0,1,.05-.11l7.43-6V26.29a.17.17,0,0,1-.08.14Zm9.84-4.68a.15.15,0,0,1-.16.15H34.21l1.61-1.53a1.15,1.15,0,0,0,.41-.88V3.08h0a.49.49,0,0,0-.49-.47h-13a1.15,1.15,0,0,0-1.15,1.15V25.94a1.14,1.14,0,0,0,1.15,1.15h4.07V28.7H19.92V1H37.68a.16.16,0,0,1,.16.15Z" />
          <path d="M16.16,2.61H3.76A1.16,1.16,0,0,0,2.61,3.76V25.94a1.15,1.15,0,0,0,1.15,1.15h12.4a1.15,1.15,0,0,0,1.15-1.15V3.76A1.16,1.16,0,0,0,16.16,2.61Zm.15,23.33a.15.15,0,0,1-.15.15H3.76a.15.15,0,0,1-.15-.15V3.76a.15.15,0,0,1,.15-.15h12.4a.15.15,0,0,1,.15.15Z" />
          <path d="M6.07,5.36a.51.51,0,0,0-.7,0,.47.47,0,0,0-.15.35.49.49,0,0,0,.15.36.64.64,0,0,0,.16.11.58.58,0,0,0,.19,0,.46.46,0,0,0,.35-.14.49.49,0,0,0,.15-.36.43.43,0,0,0,0-.19A.51.51,0,0,0,6.07,5.36Z" />
          <path d="M12.24,5.22H8.33a.5.5,0,0,0,0,1h3.91a.5.5,0,0,0,0-1Z" />
          <path d="M33.44,9.25l-3.9,3.26a.51.51,0,0,0-.07.71.53.53,0,0,0,.39.18.51.51,0,0,0,.32-.12L34.08,10a.52.52,0,0,0,.07-.71A.51.51,0,0,0,33.44,9.25Z" />
        </g>
      </g>
    </svg>
  )
}

export default Windows
